import React, { useCallback, useEffect, useContext, useState } from 'react';
import { Ratio, Button, Modal } from 'react-bootstrap';
import { DeleteObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { videosEndpoint } from '../../services/endpoints';
import { Video } from '../../interfaces';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';

import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY, FOLDER_VIDEOS } from '../../config/envVariables';

import { bucketName, programmingEndpoint, REGION_AWS, BUCKET_URL } from '../../services/endpoints';
import { deleteMovement } from '../../helpers/deleteMovement';

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}
interface LocationState {
  page: string;
}
const VideosListScreen = ({ history }: any) => {
  const { user } = useContext(AuthContext);
  console.log('user', user);

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [videoShow, setVideoShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [videoDelete, setVideoDelete] = useState('');
  const [videosList, setVideosList] = useState<Video[]>([]);
  const [totalVideos, setTotalVideos] = useState(Number);
  const [currentPage, setcurrentPage] = useState(0);
  const location = useLocation<LocationState>();
  const [forcePage, setforcePage] = useState(0);

  const videosPerPage = 15;

  const goToEdit = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
      const idVideo = currentTarget.getAttribute('data-id');
      console.log('currentPage', currentPage);

      history.push(`/editar/${idVideo}?page=${currentPage}`);
    },
    [currentPage],
  );

  const handleVideoOpen = useCallback(({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    const idVideo = currentTarget.getAttribute('data-url');
    const version = getRandomInt(1, 50);
    setVideoURL(`${BUCKET_URL}/${FOLDER_VIDEOS}/${idVideo}?v=${version}`);
    setVideoShow(true);
  }, []);

  const handleVideoClose = useCallback(() => {
    setVideoShow(false);
  }, []);

  const handleDeleteOpen = useCallback(({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    const idVideo: any = currentTarget.getAttribute('data-id');
    const idExtension: any = currentTarget.getAttribute('data-extension');
    setVideoDelete(`${idVideo}.${idExtension}`);
    setDeleteShow(true);
  }, []);

  const handleDeleteClose = useCallback(() => {
    setVideoDelete('');
    setDeleteShow(false);
  }, []);

  const getVideosList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(videosEndpoint);

      const { total, videos } = await response.json();

      if (response.status === 200) {
        setVideosList(videos);
        setTotalVideos(total);
      } else {
        console.error('Error getting videos on Videos List Screen');
      }
    } catch (error) {
      console.error('Error getting videos on Videos List Screen', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteFiles = useCallback(async () => {
    const idVideo = videoDelete.split('.').shift();
    const target = {
      Bucket: bucketName,
      Key: `${FOLDER_VIDEOS}/${videoDelete}`,
    };
    const credentials = {
      accessKeyId: AWS_ACCESS_KEY || '',
      secretAccessKey: AWS_SECRET_ACCESS_KEY || '',
    };
    const s3Client = new S3Client({ credentials, region: REGION_AWS });
    try {
      const deleteFinished = await s3Client.send(new DeleteObjectCommand(target));
      if (deleteFinished.$metadata.httpStatusCode === 204) {
        await deleteMovement(idVideo);
        handleDeleteClose();
        getVideosList();
      }
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  }, [videoDelete]);

  const handlePageClick = async (data: any) => {
    console.log('data', data);

    let handlepage = data.selected;
    console.log('handlepage', handlepage);

    setcurrentPage(handlepage);
    const videosSkip = handlepage * videosPerPage;
    try {
      const response = await fetch(videosEndpoint + `?from=${videosSkip}`);
      const { videos, total } = await response.json();
      console.log('total videos', total);

      setTotalVideos(total);
      setVideosList(videos);
    } catch {
      console.error('error catch line 174');
    }
  };
  useEffect(() => {
    getVideosList();
    console.log(location.state);
    if (location) {
      if (location.state) {
        // const {page}= location.state
        if (location.state?.page) {
          let pageNum = parseInt(location.state?.page);
          handlePageClick({ selected: pageNum });
          setforcePage(pageNum);
        }
      }
    }
  }, []);
  const onSearchChange = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const query = target.value;
      setSearch(query);
      // console.log('query', query);

      try {
        if (query !== '') {
          const urlSearch = `${programmingEndpoint}/search?movement=${target.value}&populateEquipment=true`;

          const response = await fetch(urlSearch);
          const movements = await response.json();
          console.log('movements', movements);
          let arrMovements = {
            results: movements,
            total: movements.length,
          };
          setVideosList(arrMovements.results);
          setTotalVideos(0);
        } else {
          getVideosList();
        }
      } catch {
        console.error('error catch en SEARCH line 178');
      }
    },
    [search, videosList],
  );

  return (
    <>
      {loading ? (
        <div className="alert alert-info" role="alert">
          Cargando...
        </div>
      ) : (
        <>
          <div className="mb-3 row">
            <label className="col-sm-1 col-form-label">
              <b>Buscador</b>
            </label>
            <div className="col-sm-11">
              <input
                type="text"
                className="form-control"
                value={search}
                onChange={onSearchChange}
              />
            </div>
          </div>
          <table className="table table-striped">
            <thead className="bg-bigg bg-black color-bigg color-white">
              <tr className="text-center">
                <th scope="col">Movimiento</th>
                <th scope="col">Categoria</th>
                <th scope="col">Estímulo</th>
                <th scope="col">Equipamiento</th>
                <th scope="col">V. Facil</th>
                <th scope="col">V. Dificil</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {videosList.map((video) => (
                <tr className="text-center" key={video._id}>
                  <td className="text-start">{video.title}</td>

                  <td>
                    {' '}
                    {video.category.map((category,i) => (
                      <div className="bg-movement-list" key={i}>
                        <span>{category}</span>
                      </div>
                    ))}
                  </td>

                  {/* <td>{video.estimulo}</td> */}
                  <td>
                    {' '}
                    {video.estimulo.map((estimulo,i) => (
                      <div className="bg-movement-list" key={i}>
                        <span>{estimulo}</span>
                      </div>
                    ))}
                  </td>
                  <td>
                    {' '}
                    {video.equipamiento.map((equipamiento: { title: string }, i) => (
                      <div key={i}>
                        {' '}
                        {i < 3 && (
                          <div className="bg-movement-list">
                            <span>{equipamiento.title}</span>
                          </div>
                        )}{' '}
                      </div>
                    ))}{' '}
                    {video.equipamiento.length > 3 && `+ ${video.equipamiento.length - 3}`}
                  </td>
                  <td className='small-width'>{video.easy_version && video.easy_version.title}</td>
                  <td className='small-width'>{video.hard_version && video.hard_version.title}</td>
                  <td align="center">
                    <Button
                      className="me-2 btn-bigg btn-green"
                      data-url={`${video._id}.${video.video_extension || 'mp4'}`}
                      onClick={handleVideoOpen}
                    >
                      Ver video
                    </Button>
                    <Button className="me-2 btn-bigg" data-id={video._id} onClick={goToEdit}>
                      Editar
                    </Button>
                    {user.user !== 'soporte' && (
                      <Button
                        variant="danger"
                        className="btn-bigg-delete"
                        data-id={`${video._id}`}
                        data-extension={`${video.video_extension || 'mp4'}`}
                        onClick={handleDeleteOpen}
                      >
                        Eliminar
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Siguiente'}
            breakLabel={'...'}
            pageCount={Number(totalVideos) / videosPerPage}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            initialPage={forcePage}
            onPageChange={handlePageClick}
            containerClassName={'pagination bigg-pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
          <Modal show={videoShow} onHide={handleVideoClose}>
            <Modal.Header closeButton>
              <Modal.Title>Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Ratio>
                <video controls loop autoPlay>
                  <source src={videoURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Ratio>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-bigg btn-green" onClick={handleVideoClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={deleteShow} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>Eliminar video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ¿Está seguro de que desea eliminar este archivo de forma permanente?
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-bigg btn-green" onClick={handleDeleteClose}>
                Cerrar
              </Button>
              <Button variant="danger" onClick={handleDeleteFiles}>
                Eliminar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default VideosListScreen;
